@font-face {
  font-family: 'Roboto';
  src: url('./styles/RobotoCondensed-VariableFont_wght.ttf') format('truetype');
  /* Aggiungi altre varianti del font se necessario */
}

body {
  font-family: 'Roboto', sans-serif;
}

header {
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  background: "linear-gradient(180deg, #007CC1 0%, #015F9F 100%)";
  box-shadow: 'none';
  min-width: '100vh';
  min-height: '100vh';
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center',

}

.App-logo {
  height: 40vmin;
  pointer-events: none;

}

.accuracy-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Allinea il contenuto al margine basso */
  margin-right: 0;
}

.bars-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap:"20px"
}

.bar-high {
  width: 10px;
  height: 35px;
  margin: 0 2px;
  border-radius: '20px';
  background-color: white;
  border: 2px solid black;
  transition: height 0.3s ease;
}

.bar-high-full {
  width: 10px;
  height: 35px;
  margin: 0 2px;
  border-radius: '20px';
  background-color: black;
  border: 2px solid black;
  transition: height 0.3s ease;
}

.bar-medium {

  width: 10px;
  height: 25px;
  margin: 0 2px;
  border-radius: '20px';
  background-color: white;
  border: 2px solid black;
  transition: height 0.3s ease;
}

.bar-medium-full {

  width: 10px;
  height: 25px;
  margin: 0 2px;
  border-radius: '20px';
  background-color: black;
  border: 2px solid black;
  transition: height 0.3s ease;
}

.bar-low-full {

  width: 10px;
  height: 15px;
  margin: 0 2px;
  border-radius: '8px';
  background-color: black;
  border: 2px solid black;
  transition: height 0.3s ease;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fac906;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}